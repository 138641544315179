@import "../../../sass/abstracts/variables";
@import "../../../sass/base/animations";

// .Intelligent_page{
//   padding-bottom: 20px;

// }


header {
 display: flex;
 align-items: center;
 justify-content: flex-start;

}

.left {
 img {
  width: 50px;
  height: 80px;
 }
}

.right {
 margin-left: 15px;

 .title {
  font-size: 30px;
  color: #FF330C
 }
}

.text {
 width: 100%;
 font-size: 20px;
 padding-top: 30px;
 text-align: justify;
}

.intelligent_system {
 width: 100%;
 padding-top: 30px;

}

.intelligent_system2 {
 width: 100%;
 padding-top: 20px;
}

.znimg {
 width: 100%;
}

.haoneng {
 width: 100%;
 padding-top: -8px;
}

.energy {
 font-size: 20px;
 padding-bottom: 20px;
 padding-top: 20px;
 text-align: justify;
}

.control {
 width: 260px;

}

.Internet {
 width: 100%;
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding: 30px 0;

 .Internet_title {
  width: 20%;
  font-size: 25px;
  color: #FF330D;
 }

 .Internet_text {
  font-size: 20px;
  width: 75%;
 }
}

.analysis {
 width: 100%;
 padding-bottom: 3rem;
}

.Administration {
 width: 100%;

}

.wuliangwang {
 width: 100%;
 padding-top: 18px;
}

.yunjisuan {
 width: 100%;
 padding-top: 24px;
}

.Bim {
 width: 100%;
 padding-top: 24px;
 padding-bottom: 30px;
}

.Header {
 display: flex;
 width: 100%;
 align-items: center;
 justify-content: space-between;
 text-align: center;
 padding-top: 2.5rem;

 h2 {
  color: $primary-color;
  font-size: $larger-font-size;
  white-space: nowrap;
  font-weight: bold;
 }

 hr {
  border-color: $primary-color;
  width: 100%;
  padding-left: 1rem;
  font-weight: bold;
 }
}