@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.44);
  }
}
@keyframes moveInLeft {
  0% {
    left: 20%;
  }

  100% {
    left: 60%;
  }
}
