@import "../../../../../sass/abstracts/variables";
@import "../../../../../sass/abstracts/mixins";

.Product {
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: space-between;
 height: 100%;
 width: 100%;


 img {
  width: 100%;
  padding-top: 18px;
 }

 p {
  width: 100%;
  font-size: 2rem;
  font-weight: 500;

  b {
   display: inline-block;
   padding-top: 20px;
   margin-left: 50%;
   transform: translateX(-50%);
   font-size: 14px;
   font-weight: bold;
   // color: #707070;
  }

  span {
   line-height: 50px;
   width: 100%;
   display: flex;
   text-align: justify;
   padding-top: 6px;
  }

 }
}

.Product p span {
 display: inline-block;
}