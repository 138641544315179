@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

ul li {
 list-style: none;
}

.Main {
 margin-bottom: 4rem;
 padding: 0 20%;
 width: 100%;
 margin-top: 50px;
}

.Text {
 font-size: $medium-font-size;
 display: flex;
 justify-content: space-between;
 align-items: flex-start;

 .left {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: justify;

  p {
   width: 100%;
   line-height: 26px;
   display: flex;
   justify-content: space-between;
  }
 }

 .right {
  width: 48%;

  img {
   width: 100%;
  }
 }
}

.left p:nth-child(2) {
 display: flex;
}


.AboutContainer {
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 width: 100%;
 background-color: $secondary-color;
 padding: 2.5rem 10%;
 margin-bottom: 4rem;

 h1 {
  color: $color-black;
  font-size: $huge-font-size;
  margin-bottom: 2rem;
  font-weight: bold;
 }

 p {
  color: $color-gray-dark;
  font-size: $medium-font-size;
  text-align: center;
 }
}



.Header {
 display: flex;
 width: 100%;
 align-items: center;
 justify-content: space-between;
 text-align: center;

 h2 {
  color: $primary-color;
  font-size: $larger-font-size;
  white-space: nowrap;
 }

 hr {
  border-color: $primary-color;
  width: 100%;
  margin-left: 1rem;
 }
}

// .navigation_1{
//   position: fixed;
//   right:0px;
//   bottom:0px;
// }
.concerning {
 font-size: 1.8rem;
 margin-top: 14px;
}

.Franchise {
 width: 100%;
 margin-top: 1rem;

 ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: justify;

  li {
   width: 23%;
   display: flex;
   flex-direction: column;
   text-align: justify;


   span {
    margin-top: 1rem;
    font-size: 1.8rem;
   }

   img {
    width: 100%;
   }
  }
 }
}

.zhuangli {
 width: 100%;
 margin-top: 3rem;

 li img {
  width: 100%;
 }
}

ol li {
 list-style: none;
}

.Franchise .pic {
 transform: scale(1.5);
 margin-top: 10px;
}

.Franchise .tanzc {
 padding-top: 15px;
}


.activeshow{
 height: auto;
 max-height: 400px;
 overflow: hidden;
 transition: max-height 4s;
 
}
.activehide{
height: 0;
max-height: 0;
overflow: hidden;
transition: max-height 4s;
}


 .tuandui {
 width:100%;
 display: flex;
 .tuandui_left{
  display: flex;
 }
.tuandui_right{
 width:90%;
 margin-left: 5%;


}
  ul{
   width:100%;
   display: flex;
   flex-wrap: wrap;
    position: relative;
        h5 {
         position: absolute;
         left: -20px;
         top: 60px;
        font-size: $small-font-size;
         font-weight: 800;
         width: 20px;
         color: #43476a;
         line-height: 1.07;
        }
    h3 {
     position: absolute;
     left: -20px;
     top: 50px;
     font-size: 1.6rem;
     font-weight: 800;
     width: 20px;
     color: #43476a;
     line-height: 1.07;
    }
   li{
    width: 20%;
    margin-top: 30px;
    display: flex;

    span{
  font-size: $small-font-size;
     display: flex;
     flex-wrap: wrap;
     text-align: justify;
    
     
     
    }
     p{
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
    
      // justify-content: center;
      b{
        font-size: $small-font-size;
         cursor: pointer;
         color:#43476a;
         //  color:orange;
         &:hover{
           color: #f56a00;
           
         }
      }
   
      img{
       width:140px;
       height: 140px;
       cursor: pointer;
       border-radius: 50%;
      }
     
     }
  
   }
  
  }
 }