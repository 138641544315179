@import "../../sass/abstracts/variables";
@import "../../sass/base/animations";

.Container {
 display: block;
 // padding: 1rem 0;
 position: relative;


}

.FullContainer {
 display: block;
 // padding: 1rem 0;
 position: relative;

 .Img {
  display: none;
 }

 .Text {
  display: none;
 }

 .TextBox {
  display: none;
 }
}

.Img {
 width: 16%;
 position: absolute;
 top: 45.3%;
 left: 20%;
 animation: scale 2.5s forwards;
 transform-origin: left bottom;
}

.Background {
 height: 100%;
 background-image: url("../../assets/buildings.png");
 background-size: cover;
 background-position: center;
}

.Text {
 color: $color-white;
 font-size: $huger-font-size;
 text-shadow: 2px 1px $color-black;
 position: absolute;
 top: 20%;
 left: 18%;
 animation: moveInLeft 2.5s forwards;
}

.TextBox {
 background-color: rgba($color-white, 0.8);
 font-size: $larger-font-size;
 font-weight: bold;
 padding: 0 5rem;
 position: absolute;
 top: 40%;
 left: 60%;
 opacity: 0;
 animation: fadeIn 2.5s forwards;

 & span {
  color: $primary-color;
 }

 & p:nth-child(even) {
  margin-left: 2rem;
 }
}

.SlideControl {
 position: absolute;
 transform: translate(-50%, -50%);
 top: 85%;
 left: 50%;
 display: flex;
 justify-content: space-between;
 align-items: center;
 text-align: center;
 width: 7%;

 span {
  cursor: pointer;
  font-size: $small-font-size;
  padding: 0 0.8rem;
  color: $color-black;
  background-color: $color-gray;

  &:hover {
   transform: scale(1.3);
  }
 }

 .Active {
  transform: scale(1.3);
 }
}

.BackgroundSecond {
 background-image: url("../../assets/Jumbotron/background.png");
 background-size: cover;
 background-position: center;
 height: 100%;
 position: relative;

 .WordArt {
  position: relative;
  height: 100%;
  width: 57%;
  left: 8%;
  top: 17%;

  img {
   width: 7%;
   position: absolute;
  }

  .Ran {}

  .Qi {
   left: 7%;
   top: -10%;
  }

  .Guo {
   left: 15%;
   top: -7%;
  }

  .Lu {
   left: 23%;
   top: -3%;
  }

  .Yi {
   left: 31%;
   top: 5%;
  }

  .Ti {
   left: 40%;
   top: -6%;
  }

  .Hua {
   left: 48%;
  }

  .Fu {
   left: 57%;
   top: -5%;
  }

  .Wu {
   left: 64%;
   top: -7%;
  }
 }

 .TextContainer {
  position: absolute;
  height: 50%;
  width: 60%;
  left: 8%;
  top: 33%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 3rem;

  .TextOne {
   display: flex;

   align-items: center;

   p:first-child {
    font-weight: bold;
    letter-spacing: 1rem;
    color: $color-white;
    font-size: 6rem;
   }

   p:last-child {
    letter-spacing: 0.5rem;
    width: 41%;
    font-size: 2rem;
    color: $color-white;

    span:nth-child(odd) {
     color: $primary-color;
    }
   }
  }

  .TextTwo {
   width: 52%;
   text-align: center;
   font-size: 4rem;
   font-weight: bold;
   background-color: $primary-color;

   span {
    font-weight: normal;
    color: white;
    margin: 0 1rem;
   }
  }
 }

 .RectContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 11%;
  left: 50%;

  div {
   position: absolute;
   width: 3%;

   border-top: 0.8rem solid $primary-color;
   outline: 1px solid $color-black;
  }

  .RectOne {
   top: 5%;
  }

  .RectTwo {
   top: 10%;
   left: 2.5%;
   border-color: $color-white;
  }

  .RectThree {
   top: 10%;
   left: 8%;
  }

  .RectFour {
   top: 15%;
   left: 7%;
  }
 }

 .GuoluContainer {
  position: absolute;
  top: 10%;
  left: 70%;
  width: 100%;
  height: 100%;

  img {
   position: absolute;
   width: 12%;
  }

  .FirstGuolu {
   top: -5%;
   left: -6%;
  }

  .SecondGuolu {
   top: 6%;
  }

  .ThirdGuolu {
   top: 15%;
   left: 6%;
  }
 }
}

.BackgroundThird {
 background-image: url("../../assets/Jumbotron/background.png");
 background-size: cover;
 background-position: center;
 height: 100%;
}

.BackgroundFourth {
 background-image: url("../../assets/Jumbotron/background.png");
 background-size: cover;
 background-position: center;
 height: 100%;
}

.konzhi {
 width: 100%;
 margin-top: 0.3rem;
}