@import "../../../sass/abstracts/variables";

.Nav {
 display: block;
 background-color: $primary-color;
 padding: 0 17.1%;

}

.NavBar {
 width: 100%;
 position: sticky;
 top: 0;
 z-index: 9999;
 margin-top: -1rem;
}

.Container {
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding-left: 19.8%;
 padding-right: 10%;
 padding-top: 1rem;
 background-color: $color-white;
}