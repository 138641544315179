@import "../../../../sass/abstracts/variables";

.NavItem {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 1rem 0;
  text-align: center;
  &:hover {
    background-color: $tertiary-color;
    
  }
}

.NavItem a {
  color: $color-white;
  font-size: 20px;
  cursor: pointer;
}
