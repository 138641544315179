*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

/*root*/
html {
  /*global font-size*/
  /*1 rem = 10px now*/
  /*16 * 0.625*/
  height: 100%;
  font-size: 62.5%;
  font-family: source-han-sans-simplified-c, sans-serif;
  font-style: normal;
  font-weight: 100;
}

body {
  box-sizing: border-box;
  width: 1920px;
  height: 1000px;
  overflow: auto;
  margin-left: auto;
  margin-right: auto;
}

a:link,
a:hover,
a:visited,
a:active {
  text-decoration: none;
}
