@import "../../../../sass/abstracts/variables";
.Cases {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  row-gap: 1rem;
  column-gap: 3rem;
  padding: 0 1rem 2rem 3rem;
  border: 2px solid $primary-color;

  grid-row: 1 / 2;
  grid-column: 1 / 4;
  & a {
    color: $color-black;
    font-weight: bold;
    &:hover {
      color: $primary-color;
    }
  }
  & h3 {
    grid-row: 1 / 2;
    grid-column: 1 / 3;
    font-size: $larger-font-size;
    align-self: center;
  }
  & .ImgBox {
    width: 100%;
    height: 100%;
    grid-row: 2 / 5;
    grid-column: 1 / 2;

    & img {
      width: 100%;
      height: 100%;
    }
  }
  & .TextBox {
    font-size: $small-font-size;
    & p {
      color: $color-gray;
    }
  }
  & .TextBox1 {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }
  & .TextBox2 {
    grid-row: 3 / 4;
    grid-column: 2 / 3;
  }
  & .TextBox1 {
    grid-row: 4 / 5;
    grid-column: 2 / 3;
  }
  & .TextBox1 {
    grid-row: 5 / 6;
    grid-column: 2 / 3;
  }
  & .TextBox1 {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }
}
