@import "../../sass/abstracts/variables";

.Flowchart {
  display: grid;
  padding: 1rem 3rem 1rem 0;
  background-color: $secondary-color;
  grid-template-columns: 1fr 6fr;
  grid-template-rows: 1fr 1.5fr 1.5fr;
  row-gap: 2rem;
}

.TextBoxOne {
  width: 60%;
  border: 1px solid $color-black;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  justify-content: center;
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  font-size: $small-font-size;
}

.TextBoxTwo {
  width: 60%;
  border: 1px solid $color-black;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  justify-content: center;
  grid-row: 3 / 4;
  grid-column: 1 / 2;
  font-size: $small-font-size;
}

.ListContainerOne {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-self: end;
  grid-row: 2 / 3;
  grid-column: 2 / 3;
  border-bottom: 1px solid $color-black;
  padding-left: 7.3%;
}
.ListContainerTwo {
  width: 100%;
  height: 100%;
  display: flex;
  align-self: end;
  grid-row: 3 / 4;
  grid-column: 2 / 3;
  border-bottom: 1px solid $color-black;
  padding-left: 7.3%;
}
