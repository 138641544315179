@import "../../../../sass/abstracts/variables";

.News {
 display: grid;
 grid-template-columns: 1fr;
 //grid-template-rows: repeat(5, 1fr);
 width: 100%;
 padding: 0 1rem 1rem 1rem;
 border: 2px solid $primary-color;
 justify-content: space-between;

 grid-row: 1 / 2;
 grid-column: 4 / 5;

 & .backdynamic {
  margin-top: 15px;
  font-size: $larger-font-size;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  align-self: center;
  color: #000;
 }

 & .TextBox {
  font-size: $small-font-size;

  & p {
   color: $color-gray;
  }
 }

 & .TextBox1 {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
 }

 & .TextBox2 {
  grid-row: 3/ 4;
  grid-column: 1 / 2;
 }

 & .TextBox3 {
  grid-row: 4/ 5;
  grid-column: 1 / 2;
 }

 & .TextBox4 {
  grid-row: 5 / 6;
  grid-column: 1 / 2;
 }
}

.xieshou {
 width: 100%;
 height: 140px;


}

.changuan {
 padding: 14px 0;
}