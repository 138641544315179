@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";
.Main {
  margin-bottom: 4rem;
  padding: 0 20%;
  width: 100%;
  margin-top: 50px;
}

.ProductContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: $secondary-color;
  padding: 2rem 10%;
  padding-bottom: 4.5rem;
  h1 {
    color: $color-black;
    font-size: $huge-font-size;
    margin-bottom: 2rem;
    font-weight: bold;
  }
}

.ProductList {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.ProductItem {
  color: $color-black;
  font-size: $small-font-size;
  border: 1px rgba($color-black, 0.5) solid;
  padding: 2rem 8.6rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  background-color: $color-white;
  margin-top: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: relative;
  transition: all 0.3s;

  a {
    position: absolute;
    width: 100%;
    text-align: center;
    transition: all 0.3s;
    padding: 1rem 0;
    @include absCenter;
    &:link,
    &:visited {
      color: $color-black;
    }
  }
  &:hover {
    border: 1px solid $primary-color;
    a {
      color: $primary-color;
    }
  }
}
.SelectedProductItem {
  border: 1px solid $primary-color;
  a:visited,
  a:link {
    color: $primary-color;
  }
}

.Header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  h2 {
    color: $primary-color;
    font-size: $larger-font-size;
    white-space: nowrap;
  }
  hr {
    margin-left: 1rem;
    border-color: $primary-color;
    width: 100%;
  }
}

section {
  margin-top: 2rem;
}
.CableSection {
  width: 100%;

  @include clearFix;
  .CablesImage {
    float: right;
    margin-left: 5rem;
    width: 50%;
  }
  .Text {
    font-size: $medium-font-size;
    word-break: break-all;
    text-align: justify;

    p {
      margin-top: 2rem;
    }
  }
}

.Anchor {
  display: block;
  position: relative;
  top: -17rem;
  visibility: hidden;
}
