@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.Main {
 margin-bottom: 4rem;
 padding: 0 20%;
 width: 100%;
 margin-top: 50px;
}

.ContactUsContainer {
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 width: 100%;
 background-color: $secondary-color;
 padding: 2.5rem 10%;

 .Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h3 {
   font-size: $larger-font-size;
   font-weight: bold;
   white-space: nowrap;
  }

  p {
   margin-top: 1rem;
   font-weight: bold;
  }

  .Email {
   text-decoration: underline;
  }
 }

 h1 {
  color: $color-black;
  font-size: $huge-font-size;
  margin-bottom: 2rem;
  font-weight: bold;
 }

 p {
  color: $color-gray-dark;
  font-size: $larger-font-size;
  text-align: center;
 }
}