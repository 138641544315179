@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";
.Main {
  margin-bottom: 4rem;
  padding: 0 20%;
  width: 100%;
  margin-top: 50px;
}

.ProductContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: $secondary-color;
  padding: 2.5rem 10%;
  h1 {
    color: $color-black;
    font-size: $huge-font-size;
    margin-bottom: 2rem;
    font-weight: bold;
  }
}

.ProductList {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.ProductItem {
  color: $color-black;
  font-size: $small-font-size;
  border: 1px $color-black solid;
  padding: 2rem 8.6rem;
  background-color: $color-white;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  transition: all 0.3s;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  a {
    position: absolute;
    width: 100%;
    text-align: center;
    transition: all 0.3s;
    padding: 1rem 0;
    @include absCenter;
    &:link,
    &:visited {
      color: $color-black;
    }
  }
  &:hover {
    border: 1px $primary-color solid;
    a {
      color: $primary-color;
    }
  }
}

.Header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  h2 {
    color: $primary-color;
    font-size: $larger-font-size;
    white-space: nowrap;
  }
  hr {
    border-color: $primary-color;
    width: 100%;
  }
}

section {
  margin-top: 2rem;
}
.CableSection {
  width: 100%;

  @include clearFix;
  .CablesImage {
    width: 20%;
    float: right;
    margin-left: 5rem;
  }
  .Text {
    font-size: $medium-font-size;
    word-break: break-all;
    text-align: justify;

    p {
      margin-top: 2rem;
    }
  }
}

.GallerySection {
  .Gallery {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    figure {
      width: 32%;
      img {
        width: 100%;
      }
      figcaption {
        margin-top: 2rem;
        font-size: $large-font-size;
      }
    }
  }
}

.AboutSection {
  width: 100%;
  .Banner1 {
    width: 100%;
    height: 30rem;
    margin-top: 2rem;
    position: relative;
    background-size: cover;
    background-image: linear-gradient(
        to right,
        rgba($primary-color, 0.4),
        rgba($color-white, 0.1)
      ),
      url(../../assets/home.png);
    img {
      width: 100%;
    }
    p {
      text-align: center;
      width: 100%;
      position: absolute;
      font-size: 10rem;
      @include absCenter;
      color: $color-white;
    }
  }
  .Banner2 {
    width: 100%;
    height: 30rem;
    margin-top: 2rem;
    position: relative;
    background-size: cover;
    background-image: linear-gradient(
        to right,
        rgba($primary-color, 0.4),
        rgba($color-white, 0.1)
      ),
      url(../../assets/runway.png);
    img {
      width: 100%;
    }
    p {
      text-align: center;
      width: 100%;
      position: absolute;
      font-size: 10rem;
      @include absCenter;
      color: $color-white;
    }
  }
  .Banner3 {
    width: 100%;
    height: 30rem;
    margin-top: 2rem;
    position: relative;
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(
        to right,
        rgba($primary-color, 0.4),
        rgba($color-white, 0.1)
      ),
      url(../../assets/classroom.png);
    img {
      width: 100%;
    }
    p {
      text-align: center;
      width: 100%;
      position: absolute;
      font-size: 10rem;
      @include absCenter;
      color: $color-white;
    }
  }
}

.Anchor {
  display: block;
  position: relative;
  top: -17rem;
  visibility: hidden;
}

.Anchor2 {
  display: block;
  position: relative;
  top: 10rem;
  visibility: hidden;
}
