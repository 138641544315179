@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.Main {
  margin-bottom: 2rem;
  padding: 0 20%;
  width: 100%;
 margin-top:50px;
}

.news_list {
  font-size: $medium-font-size;
  text-align: justify;
  word-break: break-all;
  justify-content: space-between;
   width: 100%;
   display: flex;
   img{
      cursor: pointer;
   }
   span{
      cursor: pointer;
   }
  
  p {
     cursor: pointer;
  }
}
  .news_list_left{
     width: 48%;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     margin-top: 15px;
      .news_img{
         width: 100%;
         height: 300px;
         margin-top: 12px;
         
      }
     .news_text{
        width: 100%;
     }
   }
   .news_list_right{
    width: 48%;
    position: relative;

      display: flex;
     flex-direction: column;
     justify-content: space-between;
     margin-top: 12px;
     .title_text{
       margin-top: -10px;
       display: flex;
       justify-content: space-between;
     }
     
    
    
   
   }
   .more{
     position: absolute;
     bottom: -40px;
     right:0;
      font-size: 18px;
      color:orange;
      cursor: pointer;
   }

.AboutContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: $secondary-color;
  padding: 2.5rem 10%;
  h1 {
    color: $color-black;
    font-size: $huge-font-size;
    margin-bottom: 2rem;
    font-weight: bold;
  }
  p {
    color: $color-gray-dark;
    font-size: $medium-font-size;
    text-align: center;
  }
}

.GallerySection {
  .Gallery {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    text-align: center;

    figure {
      width: 32%;
      position: relative;
      img {
        width: 100%;
      }
      figcaption {
        margin-top: 2rem;
        font-size: $larger-font-size;
      }

      h4 {
        color: $color-white;
        letter-spacing: 0.5rem;
        position: absolute;
        font-size: $larger-font-size;
        @include absCenter;
        top: 63%;
        z-index: 10;
      }
      p {
        color: $color-gray-dark;
        font-size: $large-font-size;
      }
    }
  }
}

.Header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  h2 {
    color: $primary-color;
    font-size: $larger-font-size;
    white-space: nowrap;
  }
  hr {
    border-color: $primary-color;
    width: 100%;
    margin-left: 1rem;
  }
}




.news_footer{
  width: 100%;
  display: flex;
  justify-content: space-between;
 
}

.newshrHeader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  
  h2 {
    color: $primary-color;
    font-size: $larger-font-size;
    white-space: nowrap;
   margin-right: 1rem;
  }
}
.GallerySection_news {
   width: 48%;
    position: relative;
   margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 
}
.hotmore{
   position: absolute;
     bottom:-40px;
     right:0;
      font-size: 18px;
      color:orange;
      cursor: pointer;
  
}
.GallerySection_news_science{
   width: 48%;
  position: relative;
   height: 960px;
   align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // background: red;
    align-items: center;

}

.hotscience{
   position: absolute;
     bottom:-40px;
     right:0;
      font-size: 18px;
      color:orange;
      cursor: pointer;
}

.newshr{
   border-color: $primary-color;
    width: 82% !important;
   
   
}
.date{
  color:#ccc;
}
.date_name{
   color:#ccc;
   white-space: nowrap;
  padding-left: 10px;
}
.title_text{
  display: flex;
  justify-content: space-between;
  // padding-top: 15px;
   
}
.title_text span:nth-child(1){
  font-weight: bold;
}
.title_hotspot{
  display: flex;
  justify-content: space-between;
  //  margin: 1rem 0;
   font-size: 18px;
   span{
     cursor: pointer;
   }
}
.title_hotspot span:nth-child(1){
  font-weight: bold;
}
.title_hot_text{
 font-size: 18px;
 overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 4;
-webkit-box-orient: vertical;
}
.title_usertext{
  font-size: 18px; 
 padding-top: 25px;
}
.title_usertext_name{
  font-size: 17px;
  font-weight: bold;
  white-space: nowrap;



}
.title_text_right{
 font-size: 17px;
 font-weight: bold;
 white-space: nowrap;
 display: flex;
 justify-content: space-between;
}
.title_usertext_name_right{
 overflow: hidden;
 text-overflow:ellipsis;
 white-space: nowrap;

}
#text_coller{
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 4;
-webkit-box-orient: vertical;
padding-top: 10px;
}
h4 p{
   cursor: pointer;
}
#text_coller{
  cursor: pointer;
 
}
.box{
   height: 300px;
   margin-top: 10px;

}
.new_more{
display: flex;
justify-content: flex-end;
font-size: 1.8rem;
margin-top: 20px;
color:#f56a00;
cursor: pointer;
}
.new_hot_more{
  position: absolute;
  bottom: 0;
  right:15px;
  font-size: 1.8rem;
  color:#f56a00;
  cursor: pointer;
}
.new_hot_more2{
    position: absolute;
  bottom: -5px;
  right:15px;
  font-size: 1.8rem;
  color:#f56a00;
  cursor: pointer;
}