@import "../../../sass/abstracts/variables";

.SearchIcon {
  width: 1.3rem;
  cursor: pointer;
  transform: translateX(200%);
}
.Input {
  background-color: $secondary-color;
  padding: 0.8rem 4rem 0.8rem 1.2rem;
  width: 74%;
  border: 1px solid $color-gray;
  transform: translateX(32%);
}

.Search {
  display: flex;
  align-items: center;
}

.Input::placeholder {
  font-size: $smaller-font-size;
  font-weight: bold;
  color: $color-gray;
  letter-spacing: 1px;
}
