@import "../../sass/abstracts/variables.scss";
@import "../../sass/abstracts/mixins.scss";

.Customer {
 border: 2px solid #ccc;
 background-color: #fff4e6;
 padding: 1.25rem 0;
 position: absolute;
 right: 0;
 bottom: 220px;
 margin-top: -100px;
 display: none;
 height: 480px;
 overflow-y: scroll !important;
 z-index: 999999999;

}

.Customer1 {
 padding: 20px 0;
 background-color: #fff4e6;
 border: 2px solid #eee;
 position: absolute;
 right: 0;
 bottom: 150px;
 margin-top: -100px;
 height: 480px;
 display: block;
 overflow-y: scroll !important;
 overflow-x: none;
 z-index: 1000000000000;
}

.kefu0 {
 width: 300px;
 align-items: center;
 display: flex;
 margin-left: 20px;
 margin-top: 10px;
 z-index: 999999999999999999999;

 ol li {
  list-style: none;
 }

 img {
  width: 30px;
  height: 30px;
  margin-bottom: 85px;
  border-radius: 4px;

 }
}

.kefu {
 width: 300px;
 align-items: center;
 display: flex;
 margin-left: 20px;
 margin-top: 30px;
 z-index: 999999999999999999999;
 position: relative;

 ol li {
  list-style: none;
 }

 img {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  top: 65px;
  z-index: 99999;

 }
}

.kefu2 {
 width: 300px;
 align-items: center;
 display: flex;
 margin-left: 20px;
 margin-top: 30px;
 z-index: 999999999999999999999;

 ol li {
  list-style: none;
 }

 img {
  width: 30px;
  height: 30px;

 }
}


.bubble {
 width: 240px;
 padding: 6px 14px;
 border-radius: 10px;
 background-color: #fff;
 position: relative;
 margin-left: 10px;
 z-index: 99999999;
 margin-top: 10px;
 font-size: $medium-font-size !important;


}


.bubble::before {
 position: absolute;
 top: 90px;
 left: -20px;

 content: '';
 width: 0;
 height: 0;
 transform: rotate(0deg);
 border-right: 10px solid #fff;
 border-bottom: 10px solid transparent;
 border-left: 10px solid transparent;
 border-top: 10px solid transparent;
}

.bubble2 {
 width: 240px;
 border-radius: 10px;
 background-color: #fff;
 position: relative;
 margin-left: 10px;
 z-index: 99999999;
 padding-bottom: 10px;
 padding-top: 10px;
 margin-left: 40px;
 margin-top: 30px;
 font-size: $medium-font-size !important;

 li {
  font-size: $medium-font-size !important;
  font-weight: normal !important;

  h4 {
   font-size: $medium-font-size !important;
   font-weight: normal !important;
  }
 }

 p {
  cursor: pointer;
  margin-left: 20px;
 }

 h3 {
  font-weight: normal;
  //  border-bottom: 1px solid #ccc;
  padding-left: 20px;
  padding-bottom: 6px;
  padding-top: 6px;
 }
}


.bubble2::before {
 position: absolute;
 top: 40px;
 left: -20px;
 content: '';
 width: 0;
 height: 0;
 transform: rotate(0deg);
 border-right: 10px solid #fff;
 border-bottom: 10px solid transparent;
 border-left: 10px solid transparent;
 border-top: 10px solid transparent;

}

.num {
 font-size: 40px;
}

.tab_kf {
 width: 100px;
 display: flex;
 align-items: center;
 // position: absolute;
 // right: 20px;
 // bottom: 0;
 margin-top: 50px;
 z-index: 100000;
 flex-direction: column;


 p {
  width: 90px;
  display: flex;
  margin: 5px 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #f56a00;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
 }

}

.tab_kf p span:nth-child(2) {
 margin-bottom: 6px;
}

.tab_kf1 {
 width: 100px;
 display: flex;
 align-items: center;
 // position: absolute;
 // right: 20px;
 // bottom: 0;
 margin-top: 50px;
 display: none;
 flex-direction: column;
 z-index: 9999999999999;

 p {
  width: 90px;
  display: flex;
  margin: 5px 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #f56a00;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;


 }

}

// .tab_kf1 p span:nth-child(2){
//   margin-bottom: 6px;
// }

.close {
 position: absolute;
 right: 10px;
 top: -5px;
 font-size: 30px;
 color: #fff;
 font-weight: bold;
 cursor: pointer;
}

.kefuRight {
 width: 300px;
 align-items: center;
 display: flex;
 justify-content: flex-end;
 margin-left: 20px;
 margin-top: 30px;
 z-index: 999999999999999999999;

 img {
  width: 30px;
  height: 30px;
  margin-top: -4px;
  margin-right: 8px;
 }

}

.bubbleRight {
 padding: 6px 14px;
 border-radius: 10px;
 background-color: #fff;
 position: relative;
 z-index: 99999999;
 width: 120px;
 text-align: center;
 border: none;
 list-style: none;

}


.bubbleRight::before {
 position: absolute;
 top: 6px;
 left: 118px;
 content: '';
 width: 0;
 height: 0;
 transform: rotate(180deg);
 border-right: 10px solid #fff;
 border-bottom: 8px solid transparent;
 border-left: 8px solid transparent;
 border-top: 8px solid transparent;
}

.hot {
 margin-top: 30px;
 display: inline-block;
 margin-left: 14px !important;
}

.explain1 {
 width: 300px;
 align-items: center;
 display: flex;
 margin-left: 20px;
 z-index: 999999999999999999999;

 ol li {
  list-style: none;
 }

 img {
  width: 30px;
  height: 30px;
  margin-top: 30px;
 }
}

.explain2 {
 width: 300px;
 align-items: center;
 display: flex;
 margin-left: 20px;
 margin-top: 60px;
 z-index: 999999999999999999999;
 padding: 4px 0;

 ol li {
  list-style: none;
 }

 img {
  width: 30px;
  height: 30px;
  margin-top: 6px;
 }
}

.bubble3 {
 width: 200px;
 border-radius: 10px;
 background-color: #fff;
 position: relative;
 margin-left: 20px;
 padding-right: 4px;
 z-index: 99999999;

 p {
  cursor: pointer;
  color: #257ce4;
  margin-left: 20px;
 }

 h3 {
  font-weight: bold;
  font-weight: normal;
  //  border-bottom: 1px solid #ccc;
  padding-left: 20px;
  padding-bottom: 6px;
  padding-top: 6px;
 }
}


.bubble3::before {
 position: absolute;
 top: 15px;
 left: -20px;
 content: '';
 width: 0;
 height: 0;
 transform: rotate(0deg);
 border-right: 10px solid #fff;
 border-bottom: 10px solid transparent;
 border-left: 10px solid transparent;
 border-top: 10px solid transparent;
}

.dianhua {
 position: relative;
}

.showdianhua {
 display: block;
 background: #ccc;
 color: #fff;
 font-size: 20px;
 white-space: nowrap;
 padding: 8px 8px;
 position: absolute;
 left: -155px;
 top: 20px;
 margin-right: 6px;
}

.hidedianhua {
 display: none;
 background: #ccc;
 color: #fff;
 font-size: 20px;
 white-space: nowrap;
 padding: 5px 4px;
 position: absolute;
 left: -30px;
 top: 0;
}

.wxinlist {
 position: relative;
}

.wxingshow {
 display: block;
 position: absolute;
 left: -120px;
 top: -20px;
 width: 120px;
 height: 110px;
}

.wxinghide {
 display: none;
 position: absolute;
 left: -120px;
 top: -20px;
 width: 120px;
 height: 110px;
}

.font_size {
 font-size: $medium-font-size;

}

.zhihui {
 margin-left: -15px;
 font-size: 14px;
}

.setnamehide {
 display: none;
 font-size: 15px;
 transition: display 5s;
 font-weight: normal;
 margin-left: 15px !important;
 line-height: 20px;

 b {
  display: inline-block;
  color: #f56a00;
 }
}

.setnameshow {
 display: block;
 font-size: 16px;
 font-size: 15px;
 margin-left: 25px !important;
 line-height: 20px;
 font-weight: normal;
 transition: display 5s;

 b {
  display: inline-block;
  color: #f56a00;
 }
}

.hot {
 margin-left: 17px;
 margin-top: -30px;
 font-size: $medium-font-size !important;
}

.dian {
 font-size: $medium-font-size;

}

.dian_diandian {
 font-size: 23px;
}

.dian_list {
 list-style: none;
 font-size: $medium-font-size;
}

.supplyshow {
 display: block;
 font-size: 16px;
 font-size: 15px;
 margin-left: 25px !important;
 line-height: 20px;
 font-weight: normal;
 transition: display 5s;


 b {
  display: inline-block;
  font-weight: normal;
  // color: #f56a00;
 }
}

.supplyhide {
 display: none;
 font-size: 14px;
 transition: display 5s;
 font-weight: normal;
 margin-left: 15px !important;
 line-height: 20px;

 b {
  display: inline-block;
  color: #f56a00;
 }
}

.serviceshow {
 display: block;
 font-size: 16px;
 font-size: 15px;
 margin-left: 25px !important;
 line-height: 20px;

 transition: display 5s;

 b {
  display: inline-block !important;
  color: #f56a00;
 }
}

.serviceshide {
 display: none;
 font-size: 14px;
 transition: display 5s;
 font-weight: normal;
 margin-left: 15px !important;
 line-height: 20px;
 word-wrap: break-word;

 b {
  display: inline-block !important;
  color: #f56a00;
 }
}

.List_name {
 height: 480px;
 position: absolute;
 right: 20px;
 bottom: -130px;
 z-index: 9999999999;




}

.konzhi {
 font-size: $medium-font-size;
 margin-left: 15px !important;
}

.ceshi {
 font-size: $medium-font-size !important;
 margin-left: 16px !important;
}
.liaxi{
  font-size: $medium-font-size !important;
  margin-left: 16px !important;

}
