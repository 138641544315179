@import "../../../../sass/abstracts/variables";

$arrow-color-1: #ef8944;
$arrow-color-2: #f09251;
$arrow-color-3: #f29c60;
$arrow-color-4: #f3a56e;
$arrow-color-5: #f5af7d;
$arrow-color-6: #f6b98c;

.Arrow-1 {
  background-color: $arrow-color-1;
  z-index: 100;
  &::before {
    border-left: 3rem solid $arrow-color-1;
  }
}
.Arrow-2 {
  background-color: $arrow-color-2;
  z-index: 99;
  &::before {
    border-left: 3rem solid $arrow-color-2;
  }
}
.Arrow-3 {
  background-color: $arrow-color-3;
  z-index: 98;
  &::before {
    border-left: 3rem solid $arrow-color-3;
  }
}
.Arrow-4 {
  background-color: $arrow-color-4;
  z-index: 97;
  &::before {
    border-left: 3rem solid $arrow-color-4;
  }
}
.Arrow-5 {
  background-color: $arrow-color-5;
  z-index: 96;
  &::before {
    border-left: 3rem solid $arrow-color-5;
  }
}
.Arrow-6 {
  background-color: $arrow-color-6;
  z-index: 95;
  &::before {
    border-left: 3rem solid $arrow-color-6;
  }
}

.Arrow {
  width: 15.7%;
  height: 6rem;
  position: relative;
  margin-left: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 3rem solid white;
    border-top: 3rem solid transparent;
    border-bottom: 3rem solid transparent;
  }
  &::before {
    content: "";
    position: absolute;
    right: -3rem;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: 3rem solid transparent;
    border-bottom: 3rem solid transparent;
  }

  &-left {
    &::after {
      border-color: $arrow-color-1;
    }
  }
  & p {
    margin-left: 3rem;
    color: $color-white;
    font-size: $medium-font-size;
  }
}
