@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.Main {
 margin-bottom: 4rem;
 padding: 0 20%;
 width: 100%;
 margin-top: 20px;
 overflow: hidden;
}

.ProductContainer {
 margin-top: 3rem;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 width: 100%;
 background-color: $secondary-color;
 box-sizing: border-box;
 padding: 2rem 0;
 padding-bottom: 4.5rem;

 h1 {
  color: $color-black;
  font-size: $huge-font-size;
  margin-bottom: 2rem;
  font-weight: bold;
 }
}

.ProductList {
 width: 100%;
 display: flex;
 flex-direction: row;
 justify-content: space-around;
 align-items: center;
 list-style: none;
}

.ProductItem {
 color: $color-black;
 font-size: $small-font-size;
 border: 1px $color-black solid;
 // padding: 2rem 8.6rem;
 width: 300px;
 height: 50px;
 white-space: nowrap;
 border: 1px solid #ccc;
 display: flex;
 align-items: center;
 background-color: $color-white;
 margin-left: 1.5rem;
 margin-right: 1.5rem;
 transition: all 0.3s;
 position: relative;
 box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

 a {
  position: absolute;
  width: 100%;
  text-align: center;
  transition: all 0.3s;
  padding: 1rem 0;
  @include absCenter;

  &:link,
  &:visited {
   color: $color-black;
  }
 }

 &:hover {
  border: 1px $primary-color solid;

  a {
   color: $primary-color;
  }
 }
}

.selectedProductItem {
 color: $primary-color;
 border: $primary-color;
 background-color: $color-white;
}

.Header {
 display: flex;
 width: 100%;
 align-items: center;
 justify-content: space-between;
 text-align: center;

 h2 {
  color: $primary-color;
  font-size: $larger-font-size;
  white-space: nowrap;
 }

 hr {
  border-color: $primary-color;
  width: 100%;
  margin-left: 1rem;
 }
}

section {
 margin-top: 2rem;
}

.CableSection {
 width: 100%;

 @include clearFix;

 .CablesImage {
  float: right;
  margin-left: 5rem;
  margin-bottom: 5rem;
  width: 50%;
 }

 .Text {
  font-size: $medium-font-size;
  text-align: justify;
  word-break: break-all;

  p {
   margin-top: 2rem;
  }
 }
}

.GallerySection {
 .Gallery {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  text-align: justify;

  figure {
   width: 32%;

   img {
    width: 100%;
   }

   figcaption {
    margin-top: 2rem;
    font-size: $medium-font-size;
    text-align: justify;

   }
  }
 }
}

.Gallery_Header {
 display: flex;
 margin-top: 2rem;
 display: flex;
 justify-content: space-between;
 text-align: justify;

 p {
  width: 48%;
  display: flex;
  flex-direction: column;

  span {
   margin-top: 2rem;
   font-size: $medium-font-size;
   text-align: justify;
  }

  img {
   width: 100%;
  }
 }
}

.AboutSection {
 width: 100%;

 .Banner1 {
  width: 100%;
  height: 25rem;
  margin-top: 2rem;
  position: relative;
  background-size: cover;
  background-position: right;
  background-image: linear-gradient(to right,
    rgba($primary-color, 0.4),
    rgba($color-white, 0.1)),
   url(../../assets/home.png);

  img {
   width: 100%;
  }

  p {
   text-align: center;
   width: 100%;
   position: absolute;
   font-size: 8rem;
   @include absCenter;
   color: $color-white;
  }
 }

 .Banner2 {
  width: 100%;
  height: 25rem;
  margin-top: 2rem;
  position: relative;
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(to right,
    rgba($primary-color, 0.4),
    rgba($color-white, 0.1)),
   url(../../assets/runway.png);

  img {
   width: 100%;
  }

  p {
   text-align: center;
   width: 100%;
   position: absolute;
   font-size: 8rem;
   @include absCenter;
   color: $color-white;
  }
 }

 .Banner3 {
  width: 100%;
  height: 25rem;
  margin-top: 2rem;
  position: relative;
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(to right,
    rgba($primary-color, 0.4),
    rgba($color-white, 0.1)),
   url(../../assets/classroom.png);

  img {
   width: 100%;
  }

  p {
   text-align: center;
   width: 100%;
   position: absolute;
   font-size: 8rem;
   @include absCenter;
   color: $color-white;
  }
 }
}

.Anchor {
 display: block;
 position: relative;
 top: -17rem;
 visibility: hidden;
}

.Banner {
 margin-top: 2rem;
}

// .Ambient{
//   width:100%;
//   display: flex;
//   margin-top: 40px;
//   justify-content: space-between;
//   align-items: center;

//   span{
//     font-size: 1.8rem;
//     color:#f56a00;
//     font-weight: bold;

//   }
// }
.ruhai_title {
 font-weight: bold;
 color: #f56a00;
 font-size: 1.8rem;
 margin-top: 2rem;
}

.ruhai_title_mao {
 font-size: 2rem;
}

i {
 font-style: normal;
}

.Ambient_text {
 margin-top: 5px;
}

.Ambient1 {
 width: 100%;
 display: flex;
 justify-content: space-between;
 align-items: center;
 margin-bottom: 30px;

 i {
  font-size: $medium-font-size;
  color: #f56a00;
  font-weight: bold;
  list-style: none;
 }

 span {
  width: 42%;
  height: 0.2rem;
  background: #f56a00;

 }
}

.unit {
 display: flex;
 width: 100%;
 justify-content: space-between;
 align-items: center;

}

.bujian {
 height: 200px;
}

.jizu_text {
 font-size: 2rem;
 color: #f56a00;
 font-weight: bold;


}

.pump_unit {
 margin-top: 2rem;

 h3 {
  width:100%;
  display: flex !important;
  align-items: center;


  p {
   display: flex;
  }
 }

 p {
  color: #f56a00;
  font-weight: bold;
 }
}

.Text_unit p {
 font-size: 1.8rem;
 text-align: justify;
 margin-top: 2rem;
}

.dansj {
 width: 100px;

}

.danshuang {
 display: flex;
 justify-content: space-between;
 width: 50%;
}

.pump_unit_yousi2 {
 font-size: 1.8rem !important;
 color: #f56a00;
 font-weight: bold;

}

.pump_unit {
 width: 100%;
 margin-top: 0;
 display: flex !important;



 h3 {
  width: 100%;
  display: flex !important;


  .jzfj {
   width: 50% !important;

  }
 }
}

.jizu {
 width: 35%;
 margin-top: 14px;
}

.jzbz {
 width: 7%;
 margin: 0 20px;
 margin-left: 35px;
}

.pump_unit_yousi {
 position: absolute;
 left: 0px;
 top: 14px;
}

.pump_unit2 {
 position: relative;

 h3 {
  display: flex;
  align-items: center;
  background: red;

  p {
   display: flex;

  }
 }

 p {
  color: #f56a00;
  font-weight: bold;
 }
}

.moduls {
 width: 70%;
 display: flex;

 align-items: center;

 span {
  font-size: 2rem;
  color: #f56a00;
  font-weight: bold;
 }
}

.mokuaihua {}

.modulright {
 width: 28%;
 display: flex;
 flex-direction: column;
 position: relative;

 img {
  width: 100%;
  margin-left: 20px;
  margin-top: 70px;
 }

 p {

  position: absolute;
  top: 18px;
  left: 20px;
  font-weight: bold;
  font-size: 18px;
  color: #f56a00;

 }
}

.modules_text {
 display: flex;
 align-items: center;
 font-size: 1.8rem;
 font-weight: bold;
 color: #f56a00;
 margin-top: 40px;

 i {
  display: flex;
  margin-top: 12px;
  font-style: normal;
  font-size: 1.8rem;
  white-space: nowrap;
  padding: 0 14px;
 }
}

.mokuai_text {
 font-size: 2rem;
 margin-bottom: 1rem;
 margin-top: 1rem;
}

.mukuaihua {
 width: 100%;
 display: flex;
 justify-content: space-between;

 img {
  width: 48%;
  margin-top: 20px;
 }
}

.rangqi {
 width: 100%;
}

.dbre_text {
 font-size: 1.8rem;
 width: 100%;
 text-align: justify;
 margin: 30px 0;
}

.advantage {
 width: 100%;
 display: flex;
 align-items: flex-start;
 justify-content: space-between;
 margin-bottom: 30px;


}

.advantage1 {
 width: 100%;
 display: flex;
 align-items: flex-start;
 justify-content: space-between;
 margin-bottom: 15px;
}

.num1 {
 width: 20%;
 font-size: 6rem;
 color: #b5b7b6;
 font-weight: bold;
 margin-top: -10px;
}

.num2 {
 width: 20%;
 font-size: 6rem;
 color: #b5b7b6;
 font-weight: bold;

}

.num3 {
 width: 20%;
 font-size: 6rem;
 color: #b5b7b6;
 font-weight: bold;
}

.num_text {
 font-size: 1.8rem;
 width: 70%;
 font-weight: normal;
 text-align: justify;

 span {
  font-weight: bold;
 }

 p {
  margin-top: 5px;
 }
}

.Classic {
 display: flex;
 justify-content: space-between;
 align-items: flex-end;

}

.diagram {
 width: 56%;

}

.yonchangjing {
 width: 42%;
 display: flex;
 flex-direction: column;
 font-size: 2rem;
 font-weight: bold;
 color: #f56a00;

 b {
  margin-left: 12px;
 }

 img {
  width: 100%;
 }
}

.application {
 width: 100%;
 margin-top: 20px;
 font-size: 22px;
 color: #f56a00;
 font-weight: bold;

 figure {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
   width: 48%;
   margin-top: 10px;

   img {
    width: 100%;
   }
  }
 }
}

.daily {
 width: 48%;
 display: flex;
 justify-content: space-between;

 img {
  width: 48%;
 }
}

.wuzhi {
 display: flex;
 width: 100%;
 justify-content: space-between;

 ul {
  width: 48%;
  list-style: none;
  margin-top: 12px;

  li img {
   width: 100%;
   height: 400px;
  }


 }
}

.bubble {
 width: 400px;
 height: 150px;
 border-radius: 10px;
 background-color: rgba(0, 0, 0, 0.5);
 position: relative;
 margin-left: 20px;
 box-sizing: border-box;
 position: absolute;
 top: 170px;
 left: -840px;
 z-index: 99999999;

 ul {
  display: flex;

  li {
   color: #fff;
   text-align: justify;

   img {
    width: 100px;
    height: 80px;
    display: inline-block;
    margin-top: 40px;
    border-radius: 4px;
   }

   p {
    margin-top: 1rem;
    line-height: 20px;
   }

   b {
    display: inline-block;
    font-size: 14px;
    color: #f56a00;
    margin-top: 10px;

   }
  }
 }
}

.bubble ul li:nth-child(2) {
 margin-right: 20px;
}


.bubble::before {
 position: absolute;
 top: 150px;
 left: 140px;
 content: '';
 width: 0;
 height: 0;
 transform: rotate(-90deg);
 border-right: 10px solid rgba(0, 0, 0, 0.5);
 border-bottom: 10px solid transparent;
 border-left: 10px solid transparent;
 border-top: 10px solid transparent;
}


.fangzi {
 width: 100%;
 display: flex;
 justify-content: center;
 position: relative;

 .fangzhi_pic {
  width: 80%;
  height: 700px;
  padding-top: 30px;
  padding-bottom: 10px;
 }

 .xiaotiao1 {
  position: absolute;
  left: -415px;
  top: -157px;
 }
}

.spot {
 position: absolute;
 left: 360px;
 top: 335px;
 width: 15px;
 height: 15px;
 background: #f56a00;
 font-size: 160px;
 color: #f56a00;
 border-radius: 50%;
 cursor: pointer;

}

.title_name_ck {

 width: 120px;
 height: 100px;


}

.spot1 {
 position: absolute;
 left: 320px;
 top: 390px;
 width: 15px;
 height: 15px;
 background: #f56a00;
 font-size: 160px;
 color: #f56a00;
 border-radius: 50%;
 cursor: pointer;
}



.bubble1 {
 width: 400px;
 height: 150px;
 border-radius: 10px;
 background-color: rgba(0, 0, 0, 0.5);
 position: relative;
 margin-left: 20px;
 position: absolute;
 top: 220px;
 left: -880px;
 z-index: 99999999;

 ul {
  display: flex;

  li:nth-child(1) {
   width: 200px;
  }

  li {
   display: flex;
   flex-direction: column;
   color: #fff;
   text-align: justify;

   p {
    line-height: 30px;
    color: #fff;
   }

   span {
    font-size: 14px;
    display: inline-block;
    margin-top: 0.6rem;
    color: #f56a00;
    padding: 6px 0;
    text-align: justify;
   }

   img {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    display: inline-block;
    margin-top: 45px;
    margin-left: 20px;
   }
  }
 }
}

.bubble1::before {
 position: absolute;
 top: 150px;
 left: 140px;
 content: '';
 width: 0;
 height: 0;
 transform: rotate(-90deg);
 border-right: 10px solid rgba(0, 0, 0, 0.5);
 border-bottom: 10px solid transparent;
 border-left: 10px solid transparent;
 border-top: 10px solid transparent;
 z-index: 99999;
}

.spot2 {
 position: absolute;
 left: 480px;
 top: 350px;
 width: 15px;
 height: 15px;
 background: #f56a00;
 font-size: 160px;
 color: #f56a00;
 border-radius: 50%;
 cursor: pointer;
}

.bubble2 {
 width: 400px;
 height: 150px;
 border-radius: 10px;
 background-color: rgba(0, 0, 0, 0.5);
 position: relative;
 margin-left: 20px;
 position: absolute;
 top: 180px;
 left: -720px;
 z-index: 99999999;

 ul {
  display: flex;

  li {
   display: flex;
   flex-direction: column;
   color: #fff;
   text-align: justify;

   p {
    color: #fff;
    line-height: 20px;
   }

   span {
    font-size: 14px;
    color: #f56a00;
    padding: 6px 0;
    text-align: justify;
    display: inline-block;
    margin-top: 0.6rem;
    margin-bottom: 0.4rem;
   }

   .img {
    width: 80px;
    height: 80px;
    display: inline-block;
    margin-top: 40px;
    margin-left: 20px;
    border-radius: 4px;
   }
  }
 }
}

.bubble2::before {
 position: absolute;
 top: 150px;
 left: 140px;
 content: '';
 width: 0;
 height: 0;
 transform: rotate(-90deg);
 border-right: 10px solid rgba(0, 0, 0, 0.5);
 border-bottom: 10px solid transparent;
 border-left: 10px solid transparent;
 border-top: 10px solid transparent;
 z-index: 99999;
}

.spot3 {
 position: absolute;
 left: 640px;
 top: 330px;
 width: 15px;
 height: 15px;
 background: #f56a00;
 font-size: 160px;
 color: #f56a00;
 border-radius: 50%;
 cursor: pointer;

}

.bubble3 {
 width: 400px;
 height: 150px;
 border-radius: 10px;
 background-color: rgba(0, 0, 0, 0.5);
 position: relative;
 margin-left: 20px;
 position: absolute;
 top: 170px;
 left: -560px;
 z-index: 99999999;

 ul {
  display: flex;

  li:nth-child(1) {
   width: 200px;
  }

  li {
   display: flex;
   flex-direction: column;

   p {
    color: #fff;
    line-height: 30px;
   }

   span {
    font-size: 14px;
    color: #f56a00;
    padding: 6px 0;
    display: inline-block;
    margin-top: 20px;
   }

   img {
    width: 80px;
    height: 80px;
    display: inline-block;
    margin-top: 30px;
    margin-left: 20px;
    border-radius: 4px;
   }
  }
 }
}

.bubble3::before {
 position: absolute;
 top: 150px;
 left: 140px;
 content: '';
 width: 0;
 height: 0;
 transform: rotate(-90deg);
 border-right: 10px solid rgba(0, 0, 0, 0.5);
 border-bottom: 10px solid transparent;
 border-left: 10px solid transparent;
 border-top: 10px solid transparent;
 z-index: 99999;
}

.spot4 {
 position: absolute;
 left: 670px;
 top: 435px;
 width: 15px;
 height: 15px;
 background: #f56a00;
 font-size: 160px;
 color: #f56a00;
 border-radius: 50%;
 cursor: pointer;
 clear: both;
}


.bubble4 {
 width: 400px;
 height: 150px;
 border-radius: 10px;
 background-color: rgba(0, 0, 0, 0.5);
 position: relative;
 margin-left: 20px;
 position: absolute;
 top: 260px;
 left: -530px;
 z-index: 99999999;

 ul {
  display: flex;

  li:nth-child(1) {
   width: 200px;
  }

  li {
   display: flex;
   flex-direction: column;

   p {
    color: #fff;
    line-height: 30px;
   }

   span {
    font-size: 14px;
    color: #f56a00;
    padding: 6px 0;
    display: inline-block;
    margin-top: 20px;
   }

   img {
    width: 80px;
    height: 80px;
    display: inline-block;
    margin-top: 30px;
    margin-left: 20px;
    border-radius: 4px;
   }
  }
 }
}

.bubble4::before {
 position: absolute;
 top: 150px;
 left: 140px;
 content: '';
 width: 0;
 height: 0;
 transform: rotate(-90deg);
 border-right: 10px solid rgba(0, 0, 0, 0.5);
 border-bottom: 10px solid transparent;
 border-left: 10px solid transparent;
 border-top: 10px solid transparent;
 z-index: 99999;
}

.spot5 {
 position: absolute;
 left: 680px;
 top: 600px;
 width: 15px;
 height: 15px;
 background: #f56a00;
 font-size: 160px;
 color: #f56a00;
 border-radius: 50%;
 cursor: pointer;
 clear: both;
}

.bubble5 {
 width: 400px;
 height: 150px;
 border-radius: 10px;
 background-color: rgba(0, 0, 0, 0.5);
 position: relative;
 margin-left: 20px;
 position: absolute;
 top: 430px;
 left: -520px;
 z-index: 99999999;

 ul {
  display: flex;

  li:nth-child(1) {
   width: 200px;
  }

  li {
   display: flex;
   flex-direction: column;
   color: #fff;
   line-height: 30px;

   span {
    font-size: 14px;
    color: #f56a00;

    display: inline-block;
    margin-top: 10px;
   }

   img {
    width: 80px;
    height: 80px;
    margin-top: 45px;
    margin-left: 20px;
    border-radius: 4px;
   }
  }
 }
}

.bubble5::before {
 position: absolute;
 top: 150px;
 left: 140px;
 content: '';
 width: 0;
 height: 0;
 transform: rotate(-90deg);
 border-right: 10px solid rgba(0, 0, 0, 0.5);
 border-bottom: 10px solid transparent;
 border-left: 10px solid transparent;
 border-top: 10px solid transparent;
 z-index: 99999;
}

.yychangjing {
 width: 100%;
 margin-top: 20px;

 img {
  width: 100%;
 }
}

.yychangjing_title {
 width: 100%;
 display: flex;
 justify-content: space-around;
 margin-top: 10px;

 span {
  font-size: $medium-font-size;

 }
}

.YINYON {
 width: 100%;
 margin-top: 3rem;

}

.YINYON_text {
 width: 100%;
 display: flex;
 justify-content: space-around;
 text-align: justify;
 padding-top: 5px;
 padding-bottom: 2rem;

 span {
  font-size: $medium-font-size;
 }

}

.lingyu {
 width: 100%;
 margin-top: 4.5rem;

 p {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  span {
   width: calc(100%/7.8);
   text-align: center;
   font-size: $medium-font-size;

  }
 }

 img {
  width: 100%;
 }
}

.RQGL {
 width: 100%;
 margin-top: 2rem;

 img {
  width: 100%;
 }
}

.Heating {
 width: 100%;
 display: flex;
 justify-content: space-between;
 margin-top: 18px;

 span {
  width: calc(100%/7.5);
  text-align: center;
  font-size: $medium-font-size;
 }
}


.hengxian {
 width: 100%;
 background: #f56a00;
 margin-bottom: 40px;
}

.hengxian2 {
 width: 100%;
 margin-top: 20px;
 background: #f56a00;
 margin-bottom: 40px;
}

.Screw {

 display: flex;
 align-items: center;

 span {
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  color: #000;
  display: inline-block;
  padding-left: 30px;
  border-top: 2px dotted #ccc;
 }

 img {
  width: 70px;
  height: 70px;
 }
}

.arrow {
 display: flex;
 flex-direction: column;

 img {
  width: 100px;
  padding: 24px 0;

 }
}

.faredlan {
 width: 100%;
 margin-top: 0.3rem;
}

.konqiyuan {
 width: 100%;
 margin-top: 0.3rem;
}

.guolv {
 width: 100%;
 margin-top: 0.3rem;
}

.Dxianlan {
 width: 100%;
 margin-top: 0.3rem;
}
