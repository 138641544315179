@import "../../../../../sass/abstracts/variables";
@import "../../../../../sass/abstracts/mixins";

.wrap {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 height: 668px;
}


.Productkonqyuan {
 display: flex;
 width: 100%;
 justify-content: space-between;



 p {
  width: 48%;
  display: flex;
  flex-direction: column;
  text-align: center;


  span {
   font-size: 1.5rem;
   font-weight: bold;
   padding-top: 8px;
   color: #656565;
   margin-left: 70%;
   transform: translate(-70%);
   white-space: nowrap;

  }

  img {
   width: 500px;
   height: 460px;
   padding-top: 18px;
  }
 }


}

// h3 {
//  width: 50%;
//  display: flex;
//  flex-direction: column;


// }

// h3 span {
//  display: flex;
//  flex-direction: column;

//  b {
//   font-size: 1.5rem;
//   margin-left: 70%;
//   transform: translate(-70%);
//   padding: 6px;
//   color: #656565;
//  }

//  img {
//   width: 80%;
//   margin-left: 20%;

//  }
// }
.title_names{
 width: 50%;
  display: flex;
  flex-direction: column;
 
}

.title_names span {
 display: flex;
 flex-direction: column;

 b {
  font-size: 1.5rem;
  margin-left: 70%;
  transform: translate(-70%);
 padding-top: 5px;
 padding-bottom: 5px;
  color: #656565;
 }

 img {
  width: 80%;
  margin-left: 20%;
   padding-top: 18px;

 }
}
.text {
 font-size: 2rem;

 display: flex;
 flex-direction: column;
 justify-content: space-between;


}

.title {
 font-size: 14px;
 display: flex;
 justify-content: center;
 font-weight: bold;
 margin-bottom: 10px;

}


.title2 {
 line-height: 50px;
 text-align: justify;
}

.konqiyuan3{
 margin-top: -18px;
}