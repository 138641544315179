@import "../../../../sass/abstracts/variables";

.Dropdown {
  width: 100%;

  .Toggle {
    width: 100%;
    border: none;
    background-color: $primary-color;
    color: $color-white;
    font-size: $smaller-font-size;
    position: relative;
    padding: 1rem 0 1rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 1px $color-white;
    &:hover {
      background-color: $tertiary-color;
    }
    &:after {
      position: absolute;
      left: 87%;
      top: 40%;
      width: 0;
      height: 0;
      content: "";
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 10px solid $color-white;
    }
    .MidDot {
      color: $color-white;
      font-size: $medium-font-size;
      margin-left: 6%;
    }
  }
  .Toggled {
    &:after {
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: none;
      border-top: 10px solid $color-white;
    }
  }
  .Menu {
    list-style: none;
    background-color: $primary-color;
    .Item {
      cursor: pointer;
      padding: 0.5rem 0;
      color: $color-white;
      width: 100%;
      font-size: $smaller-font-size;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      a {
        width: 100%;
        font-size: $smaller-font-size;
      }

      .MidDot {
        color: $color-white;
        font-size: $small-font-size;
        margin-left: 14%;
      }
      &:hover {
        background-color: $tertiary-color;
      }
    }
  }
}

.Items {
  cursor: pointer;
  list-style: none;
  background-color: $primary-color;

  .Item {
    padding: 0.5rem 0;
    color: $color-white;
    width: 100%;
    font-size: $smaller-font-size;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    a {
      width: 100%;
      font-size: $smaller-font-size;
    }

    .MidDot {
      color: $color-white;
      font-size: $medium-font-size;
      margin-left: 6%;
    }
    &:hover {
      background-color: $tertiary-color;
    }
  }
}
