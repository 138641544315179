

.container {
 padding: 20px 200px;
 width: calc(100vw - 425px);
 display: flex;
 flex-direction: column;
 height: 400px;
}
.container:before {
 content: none;
 clear:both;
}

.container .sparepartsbase {
 position: absolute;

 top: 100px;
 left: 200px;
 width: 800px;
 height: 500px;
 background-size: auto 500px;
 background-repeat: no-repeat;
 transition: background-position 1s linear;
 transform: translate3d(0, 0, 0);

}
.nexts{
 text-align: center;
}
