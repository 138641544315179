@import "../../sass/abstracts/variables";
.Legal {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  & .Icon {
    width: 2rem;
    margin-right: 5px;
  }

  & p {
    font-size: $small-font-size;
  }
}
