@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";
.Gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 80%;
  row-gap: 2rem;
  column-gap: 2rem;
  padding-left: 3rem;
}

.Img1 {
  width: 100%;
  height: 100%;
  grid-row: 1 / 3;
  grid-column: 1 / 3;
  position: relative;
  background-color: rgba($primary-color, 0.5);
  & .Text {
    @include absCenter;
    color: $color-white;
    font-size: $huge-font-size;
    opacity: 0;
  }
  &:hover img {
    opacity: 0.3;
    filter: grayscale(100%);
  }
  &:hover .Text {
    opacity: 1;
  }
}
.Img2 {
  width: 100%;
  height: 100%;
  grid-row: 2 / 3;
  grid-column: 3 / 4;
  position: relative;
  background-color: rgba($primary-color, 0.5);
  & .Text {
    @include absCenter;
    color: $color-white;
    font-size: $larger-font-size;
    opacity: 0;
  }
  &:hover img {
    opacity: 0.3;
    filter: grayscale(100%);
  }
  &:hover .Text {
    opacity: 1;
  }
}
.Img3 {
  width: 100%;
  height: 100%;
  grid-row: 1 / 2;
  grid-column: 3 / 4;
  position: relative;
  background-color: rgba($primary-color, 0.5);
  & .Text {
    @include absCenter;
    color: $color-white;
    font-size: $larger-font-size;
    opacity: 0;
  }
  &:hover img {
    opacity: 0.3;
    filter: grayscale(100%);
  }
  &:hover .Text {
    opacity: 1;
  }
}

.Gallery img {
  width: 100%;
  height: 100%;
}

.Gallery * {
  transition: all 0.4s ease-in-out;
}
