@import "./sass/base/base";

.iconfont {
 font-family: "iconfont" !important;
 font-size: 16px;
 font-style: normal;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}

@font-face {
 font-family: 'iconfont';
 src: url('../src/assets/iconfonts/font_os03vuxgq1/iconfont.eot');
 src: url('../src/assets/iconfonts/font_os03vuxgq1/iconfont.eot?#iefix') format('embedded-opentype'),
  url('../src/assets/iconfonts/font_os03vuxgq1/iconfont.woff2') format('woff2'),
  url('../src/assets/iconfonts/font_os03vuxgq1/iconfont.woff') format('woff'),
  url('../src/assets/iconfonts/font_os03vuxgq1/iconfont.ttf') format('truetype'),
  url('../src/assets/iconfonts/font_os03vuxgq1/iconfont.svg#iconfont') format('svg');
}

@font-face {
 font-family: 'iconfont';
 src: url('../src/assets/iconfonts/font_ql970i7ksh8/iconfont.eot');
 src: url('../src/assets/iconfonts/font_ql970i7ksh8/iconfont.eot?#iefix') format('embedded-opentype'),
  url('../src/assets/iconfonts/font_ql970i7ksh8/iconfont.woff2') format('woff2'),
  url('../src/assets/iconfonts/font_ql970i7ksh8/iconfont.woff') format('woff'),
  url('../src/assets/iconfonts/font_ql970i7ksh8/iconfont.ttf') format('truetype'),
  url('../src/assets/iconfonts/font_ql970i7ksh8/iconfont.svg#iconfont') format('svg');
}

@font-face {
 font-family: 'iconfont';
 src: url('../src/assets/iconfonts/font_eefg88bn3bu/iconfont.eot');
 src: url('../src/assets/iconfonts/font_eefg88bn3bu/iconfont.eot?#iefix') format('embedded-opentype'),
  url('../src/assets/iconfonts/font_eefg88bn3bu/iconfont.woff2') format('woff2'),
  url('../src/assets/iconfonts/font_eefg88bn3bu/iconfont.woff') format('woff'),
  url('../src/assets/iconfonts/font_eefg88bn3bu/iconfont.ttf') format('truetype'),
  url('../src/assets/iconfonts/font_eefg88bn3bu/iconfont.svg#iconfont') format('svg');
}

@font-face {
 font-family: 'iconfont';
 src: url('../src/assets/iconfonts/font_n0x45ewobyk/iconfont.eot');
 src: url('../src/assets/iconfonts/font_n0x45ewobyk/iconfont.eot?#iefix') format('embedded-opentype'),
  url('../src/assets/iconfonts/font_n0x45ewobyk/iconfont.woff2') format('woff2'),
  url('../src/assets/iconfonts/font_n0x45ewobyk/iconfont.woff') format('woff'),
  url('../src/assets/iconfonts/font_n0x45ewobyk/iconfont.ttf') format('truetype'),
  url('../src/assets/iconfonts/font_n0x45ewobyk/iconfont.svg#iconfont') format('svg');
}

@font-face {
 font-family: 'iconfont';
 src: url('../src/assets/iconfonts/font_q0kx6jaj8ag/iconfont.eot');
 src: url('../src/assets/iconfonts/font_q0kx6jaj8ag/iconfont.eot?#iefix') format('embedded-opentype'),
  url('../src/assets/iconfonts/font_q0kx6jaj8ag/iconfont.woff2') format('woff2'),
  url('../src/assets/iconfonts/font_q0kx6jaj8ag/iconfont.woff') format('woff'),
  url('../src/assets/iconfonts/font_q0kx6jaj8ag/iconfont.ttf') format('truetype'),
  url('../src/assets/iconfonts/font_q0kx6jaj8ag/iconfont.svg#iconfont') format('svg');
}

@font-face {
 font-family: 'iconfont';
 src: url('../src/assets/iconfonts/font_twtlulhro88/iconfont.eot');
 src: url('../src/assets/iconfonts/font_twtlulhro88/iconfont.eot?#iefix') format('embedded-opentype'),
  url('../src/assets/iconfonts/font_twtlulhro88/iconfont.woff2') format('woff2'),
  url('../src/assets/iconfonts/font_twtlulhro88/iconfont.woff') format('woff'),
  url('../src/assets/iconfonts/font_twtlulhro88/iconfont.ttf') format('truetype'),
  url('../src/assets/iconfonts/font_twtlulhro88/iconfont.svg#iconfont') format('svg');
}