@import "../../../../sass/abstracts/variables";

.SideBarBack {
 width: 22%;
 background-color: $primary-color;
 border-radius: 1rem;
 // height: 780px;
}

.SideBarFront {
 display: flex;
 flex-direction: column;
 justify-content: space-around;
 background-color: $color-white;
 width: 93%;
 height: 92%;
 border-radius: 1rem;
 margin: 0 auto;
 padding-left: 2rem;
}

.Title {
 font-size: $large-font-size;
 color: $color-white;
 text-align: center;
 margin-bottom: 1rem;
}

.Lists h3 {
 font-size: $large-font-size;
 text-decoration: underline;
}

.Lists li {
 font-size: $medium-font-size;
 margin-left: 2rem;
 margin-top: 0.5rem;
 opacity: 0.8;

 a {
  cursor: pointer;
  color: inherit;

  &:hover {
   color: $primary-color;
  }
 }
}