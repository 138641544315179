@import "../../../sass/abstracts/variables";
.NavItems {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.8rem !important;
  color:#fff !important;

}
