@import "../../../sass/abstracts/variables";
.KeyWordSearch {
 display: flex;
 justify-content: space-between;
 width: 50%;
 align-items: center;
 padding-top: 40px;
 margin-left: 50%;
 transform: translate(-50%);

 a {
  font-size: 18px;
 }
}

.KeyWordSearch h4 {
 font-size: 18px;
}

.Text {
 font-size: $smaller-font-size;
 color: $color-gray-dark;
 font-weight: bold;
 opacity: 0.9;
}

.Link {
 font-size: $smaller-font-size;
 color: $color-gray-dark;
 opacity: 0.85;
}

@media screen and (max-width:980px ){
  .KeyWordSearch {
  display: flex;
  justify-content: space-between;
  width: 50%;
  align-items: center;
  padding-top: 50px;
  margin-left: 50%;
  transform: translate(-50%);
  a{
    font-size: 18px;
  }
}

.KeyWordSearch h4{
  font-size: 18px;  
}

.Text {
  font-size: $smaller-font-size;
  color: $color-gray-dark;
  font-weight: bold;
  opacity: 0.9;
}

.Link {
  font-size: $smaller-font-size;
  color: $color-gray-dark;
  opacity: 0.85;
}
}
