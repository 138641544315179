@import "../../../../sass/abstracts/variables";

.Container {
 width: 80%;
 padding-left: 3rem;
 padding-top: 2rem;
 height: 725px;


}

.Lists {
 display: flex;
 list-style: none;
 font-size: $larger-font-size;
 justify-content: space-between;
 padding-right: 23%;
 padding-bottom: 1rem;

 li {
  cursor: pointer;
 }
}

.BreakLine {
 border: 2px solid $primary-color;
 border-radius: 1rem;
 margin-bottom: 2rem;
}

.Products {
 width: 100%;
 display: flex;
 justify-content: center;
}



.Active {
 color: $primary-color;
 font-weight: bold;
}
