@import "../../sass/abstracts/variables";
.Main {
  padding: 0 20%;
  width: 100%;
}

.BreakLine {
  border: 2px solid $primary-color;
  border-radius: 1rem;
  margin-bottom: 2rem;
}
