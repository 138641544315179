$primary-color: #f56a00;
$secondary-color: #fff4e6;
$tertiary-color: #ff7c00;

$color-black: #222222;
$color-white: #ffffff;

$color-gray: #bfbfbf;
$color-gray-dark: #707070;

$huger-font-size: 4.2rem;
$huge-font-size: 3.5rem;
$larger-font-size: 2.5rem;
$large-font-size: 2rem;
$medium-font-size: 1.8rem;
$small-font-size: 1.5rem;
$smaller-font-size: 1.3rem;
$smallest-font-size: 1rem;
