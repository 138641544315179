@import "../../sass/abstracts/variables";

.Footer {
 display: flex;
 justify-content: space-between;
 margin-top: 2rem;
 border-top: 4rem solid $primary-color;
 padding: 0 20%;
 background-color: $secondary-color;
 // position: relative;
 // bottom: 0;
 // left: 0;
 // overflow: hidden;


 & .Contact {
  width: 45%;
  padding: 2rem 0;

  & h3 {
   font-size: $medium-font-size;
   white-space: nowrap;
  }

  & p {
   font-size: $small-font-size;
   margin-top: 0.5rem;
  }
 }

 & .SocialMedia {
  display: flex;
  width: 45%;
  justify-content: space-between;
  padding: 2rem 0;

  & .Chrome {
   width: 100%;
   display: flex;
   justify-content: flex-end;
   align-items: flex-start;

   & .QR {
    width: 75%;
   }

   & .Icon {
    width: 2rem;
    margin-right: 8px;
   }
  }

  & .Weibo {
   width: 100%;
   display: flex;
   justify-content: flex-end;
   align-items: flex-start;

   & .QR {
    width: 75%;
   }

   & .Icon {
    width: 2rem;
    margin-right: 8px;
   }
  }

  & .Wechat {
   display: flex;
   justify-content: flex-end;
   align-items: flex-start;
   width: 100%;

   & .QR {
    width: 75%;
   }

   & .Icon {
    width: 2rem;
    margin-right: 8px;
   }
  }
 }
}